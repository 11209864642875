"use strict";
// Class definition

var Metronic = (function () {
  // private functions
  var initDateFields = function () {
    // $(".date-input").mask("00/00/0000", {
    //   placeholder: "dd/mm/yyyy",
    // });

    var dueDate = $(form.querySelector('[name="due_date"]'));
    dueDate.flatpickr({
      enableTime: false,
      dateFormat: "d, M Y",
    });

    // $(".date-input").daterangepicker(
    //   {
    //     singleDatePicker: true,
    //     showDropdowns: true,
    //     minYear: 1901,
    //     maxYear: parseInt(moment().format("YYYY"), 10),
    //   },
    //   function (start, end, label) {

    //   }
    // );
  };

  var initSelect2 = function () {
    var elements = [].slice.call(
      document.querySelectorAll(
        '[data-control="select2"], [data-kt-select2="true"]'
      )
    );

    elements.map(function (element) {
      var options = {
        dir: document.body.getAttribute("direction"),
      };

      if (element.getAttribute("data-hide-search") == "true") {
        options.minimumResultsForSearch = Infinity;
      }

      $(element).select2(options);
    });
  };

  return {
    // public functions
    initDateFields: function () {
      initDateFields();
      initSelect2();
    },
  };
})();
